import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';

@Component({})
export default class CustomerRequestComponent extends Vue {
  public load = false;
  public form: any;

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Ficha de clientes | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Ofrecemos soluciones innovadoras en mobiliario para oficinas. Asesoría, diseño e implementación de espacios de trabajo. Hacemos con pasión Muebles de Oficina.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/ficha-de-cliente`,
        },
        {
          property: 'og:title',
          content: 'Ficha de clientes | Dunati',
        },
        {
          property: 'og:description',
          content: 'Ofrecemos soluciones innovadoras en mobiliario para oficinas. Asesoría, diseño e implementación de espacios de trabajo. Hacemos con pasión Muebles de Oficina.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/ficha-de-cliente`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/ficha-de-cliente`
          }
        ]
      }
      */
    };
  }

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');

    // (<any>Vue).loadScript("https://podio.com/webforms/15146250/1015299.js")
    // .then(() => {
    //   //console.log((<any>window)._podioWebForm);
    // })
    // .catch(() => {
    // });
    this.load = true;
  }

  public beforeDestroy() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public getUrl() {
    const link = window.location.href;
    return `https://podio.com/webforms/15146250/1015299?e=true#${link}`;
  }
}

